import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./plugins/bootstrap/bootstrap.min.css";
import "./plugins/themify-icons/themify-icons.css";
import "./plugins/slick/slick.css";
import "./plugins/Venobox/venobox.css";
import "./plugins/aos/aos.css";
import "./css/style.css";
import "./Button.css";
import Background from './images/hero-area/banner-bg.png';
import Footer from "./images/backgrounds/footer-bg.png"
import bg_0 from "./images/background-shape/feature-bg-2.png";
import bg_1 from "./images/background-shape/seo-ball-1.png";
import bg_2 from "./images/background-shape/seo-half-cycle.png";
import bg_3 from "./images/background-shape/green-dot.png";
import bg_4 from "./images/background-shape/blue-half-cycle.png";
import bg_5 from "./images/backgrounds/seo-bg.png";
import bg_6 from "./images/background-shape/yellow-triangle.png";
import bg_7 from "./images/background-shape/service-half-cycle.png";
import bg_8 from "./images/background-shape/team-bg-triangle.png";
import bg_9 from "./images/background-shape/seo-ball-2.png";
import bg_11 from "./images/backgrounds/service-bg.png"
import I1 from "./images/my/luffy-1.png";
import I2 from "./images/my/zoro-1.png";
import I3 from "./images/my/sanji.png";
import I4 from "./images/my/luffy-2.png";
import bg_10 from "./images/backgrounds/team-bg.png";
import bg_12 from "./images/background-shape/team-bg-dots.png";
import bg_13 from "./images/background-shape/green-half-cycle.png";
import Button_GLogin from './Button';
import Heading from './Heading';
import qR from './images/my/qr.jpeg'
import boa from './images/my/boa.png';
import robin from './images/my/robin.png'
import naac from './images/my/nacc.png';
import c2 from './images/my/c2.png';

function Registration({login, logout, added}) {
    const [ form1, setForm1 ] = useState(null);
    const [ form2, setForm2 ] = useState(null);
    // const [ profile, setProfile ] = useState(null);


const sendData = (t_id) => {
  if(t_id === form2.transaction_id) {
    const data = {
      crew_name: form1.crew_name,
      captain_name: form1.captain_name ,
      phone_no: form1.phone_no,
      email: login.email,
      member1_name: form1.member1_name,
      member2_name: form1.member2_name,
      member3_name: form1.member3_name,
      member4_name: form1.member4_name,
      member5_name: form1.member5_name,
      college_name: form1.college_name,
      sender_name: form2.sender_name,
      upi_id: form2.upi_id,
      transaction_id: form2.transaction_id,
      verify: 0,
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
    }
    // const headers = {
    //     "Content-Type": "multipart/form-data"
    //   };
    axios
    .post(`${process.env.REACT_APP_API_URL}crew`,data)
    .then((response) => {
      added(response.data.id);
      console.log('Form submitted:', response.data);
    })
    .catch((error) => {
      console.error('Error submitting form:', error);
    });
  } else {
    alert('wrong TID');
  }
}



    return(
        <>
<section className="section-lg contact regipage" style={{marginBottom:-250}}>
    <div className="container" style={{marginTop:-120}}>
        <div className='float-right mr-5' >
          
        <button onClick={logout} className="btn btn-primary ml-lg-3 primary-shadow ">Logout</button><br/>
        <span style={{fontSize:20,float:'right'}}>Hi, {login.name}</span>
        </div>
        <div className="row">
            <div className="col-lg-3 text-center">
            <img src={boa} className='boa' />
            </div>
            <div className="col-lg-6 text-center">
                <h2 className="section-title pt-5 mt-5" style={{fontWeight:700}}>{form2 ? 'PAYMENT VERIFY' : form1 ? 'PAYMENT' : 'REGISTRATION'}</h2>
            </div>
        </div>
        {form2 ? <form onSubmit={(e) => {
            e.preventDefault();
            sendData(e.target.transaction_id.value);
            e.target.reset();
        }}>
            <div className=" row contact-bg p-3 p-lg-5 rounded mb-0 ml-2 mr-2">
                <div className='txn' style={{margin:"auto",textAlign:'center'}}>
                    <p style={{fontWeight:700}}>PLEASE RE-ENTER THE YOUR TRANSACTION ID TO VERIFY YOUR PAYMENT</p>
                  <input type="tel" pattern="[0-9]{12}" title='Transaction ID should only be 12 digits' className="form-control mb-3" id="transaction_id" name="transaction_id" placeholder="Your Transaction ID" defaultValue='' required/>
                  <button type="submit" value="send" className="btn btn-secondary">DONE</button>
                </div>
            </div>
        </form> : form1 ? <form onSubmit={(e) => {
            e.preventDefault();
            const data = {
              sender_name: e.target.sender_name.value,
              upi_id: e.target.upi_id.value,
              transaction_id: e.target.transaction_id.value,
            }
            setForm2(data);
            e.target.reset();
        }}>
            <div className="row contact-bg p-3 p-lg-5 rounded mb-0 ml-2 mr-2">
                <div className="col-lg-6 mb-4 mb-lg-0">
                    <p style={{fontWeight:700}}>SCAN THIS QR CODE TO PAY THE ENTRY FEES : <span style={{fontSize:25}}>&nbsp;&nbsp;<u>₹300</u></span></p>
                    <img src={qR} width={'100%'}/>
                </div>
                <div className="col-lg-6">
                    <p style={{fontWeight:700}}><u>NOTE</u> : AFTER THE SUCCESSFULL TRANSACTION PLEASE ENTER THE VALID <u>12 DIGIT TXIN ID</u></p>
                    <p style={{fontWeight:700}}>YOU CAN FIND THE TXIN ID LIKE - <br/>
                    PHONE PE : <u>UTR</u><br/>
                    PAYTM : <u>UPI REF ID</u><br/>
                    GOOGLE PAY : <u>UPI TRANSACTION ID</u><br/>
                    AMAZON PAY : <u>UPI TRANSACTION ID</u></p>
                    <input type="tel" pattern="[0-9]{12}" title='Transaction ID should only be 12 digits' className="form-control mb-3" id="transaction_id" name="transaction_id" defaultValue={null} placeholder="Your Transaction ID" required/>
                    <input type="text" pattern="[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}" title='UPI id must be valid' className="form-control mb-3" id="upi_id" name="upi_id" placeholder="Your UPI ID" defaultValue={null} required/>
                    <input type="text" className="form-control mb-3" id="sender_name" name="sender_name" placeholder="Your Sender Name" defaultValue={null} required/>
                    <button type="submit" value="send" className="btn btn-secondary float-right">DONE</button>
                </div>
            </div>
        </form>    :
        <form onSubmit={(e) => {
            e.preventDefault();
            const data = {
              crew_name: e.target.crew_name.value,
              captain_name: e.target.captain_name.value ,
              phone_no: e.target.phone_no.value,
              member1_name: e.target.member1_name.value,
              member2_name: e.target.member2_name.value,
              member3_name: e.target.member3_name.value,
              member4_name: e.target.member4_name.value,
              member5_name: e.target.member5_name.value,
              college_name: e.target.college_name.value,
            }
            setForm1(data);
            e.target.reset();
        }}>
            <div className="row contact-bg p-3 p-lg-5 rounded mb-0 ml-2 mr-2"> 
    <div className="col-lg-6 mb-4 mb-lg-0"> 
        <input type="text" className="form-control mb-3" id="crew_name" name="crew_name" placeholder="Your Crew Name" required/> 
        <input type="text" className="form-control mb-3" id="captain_name" name="captain_name" placeholder="Your Captain Name" required/>
        <input type="text" className="form-control mb-3" id="member1_name" name="member1_name" placeholder="Your Crew Member 2" required/> 
        <input type="text" className="form-control mb-3" id="member2_name" name="member2_name" placeholder="Your Crew Member 3" required/> 
        <input type="text" className="form-control mb-3" id="member3_name" name="member3_name" placeholder="Your Crew Member 4" required/> 
        
    </div> 
    <div className="col-lg-6"> 
        
        <input type="text" className="form-control mb-3" id="member4_name" name="member4_name" placeholder="Your Crew Member 5 (Optional)"/> 
        <input type="text" className="form-control mb-3" id="member5_name" name="member5_name" placeholder="Your Crew Member 6 (Optional)"/> 
        <input type="tel" className="form-control mb-3" title='Phone No should only be 10 digits' id="phone_no" name="phone_no" placeholder="Your Phone (Whatsapp)" pattern="[0-9]{10}" required/> 
        <input type="text" className="form-control mb-3" id="college_name" name="college_name" placeholder="Your College Name" required/> 
        <button type="submit" value="send" className="btn btn-secondary btn-block mt-3">NEXT</button> 
    </div> 
</div>
        </form>} 
        
    </div>
    <img className="contact-bg-1 up-down-animation" src={bg_0} alt="background-shape"/>
    <img className="contact-bg-2 left-right-animation" src={bg_13} alt="background-shape"/>
    <img className="contact-bg-3 up-down-animation" src={bg_3} alt="background-shape"/>
    <img className="contact-bg-4 left-right-animation" src={bg_7} alt="background-shape"/>
    <img className="contact-bg-5 up-down-animation" src={bg_0} alt="background-shape"/>
</section>

<footer className="footer-section footer" style={{backgroundImage: "url(" + Footer + ")"}}>
  <div className="container">
    <div className="row pt-3">
    <div className="col-lg-12 text-center zindex-1" style={{marginTop:-200}}>
    <img src={c2} style={{width:80, float:'left'}}/>
    <img src={naac} style={{width:80, float:'right'}}/>
    <h4 className='mt-3 pt-2' style={{fontWeight:700,fontSize:25}}>SURYODAYA  COLLEGE  OF  ENGINEERING  AND  TECHNOLOGY,  NAGPUR</h4>
    <h3 className='neon'>YOUTH TECH CONNECT 2024</h3>
    <h5 className='mb-5' style={{fontSize:20,color:'#b393d3'}}>20 FEB | 8 AM ONWARDS</h5>
    <p className=''>Support Contact : @Tanmay 820822895 | @Kunal 7083005425 | @Aadesh 8830857304</p>
    <p>credits to @OnePiece @EiichiroOda @ToeiAnimation & Team</p>
    </div>
    </div>
  </div>
</footer>
</>
    );
}
export default Registration;