import React, { useState, useEffect } from 'react';
import "./plugins/bootstrap/bootstrap.min.css";
import "./plugins/themify-icons/themify-icons.css";
import "./plugins/slick/slick.css";
import "./plugins/Venobox/venobox.css";
import "./plugins/aos/aos.css";
import "./css/style.css";
import Background from './images/hero-area/banner-bg.png';
import Footer from "./images/backgrounds/footer-bg.png"
import bg_0 from "./images/background-shape/feature-bg-2.png";
import bg_1 from "./images/background-shape/seo-ball-1.png";
import bg_2 from "./images/background-shape/seo-half-cycle.png";
import bg_3 from "./images/background-shape/green-dot.png";
import bg_4 from "./images/background-shape/blue-half-cycle.png";
import bg_5 from "./images/backgrounds/seo-bg.png";
import bg_6 from "./images/background-shape/yellow-triangle.png";
import bg_7 from "./images/background-shape/service-half-cycle.png";
import bg_8 from "./images/background-shape/team-bg-triangle.png";
import bg_9 from "./images/background-shape/seo-ball-2.png";
import bg_11 from "./images/backgrounds/service-bg.png"
import I1 from "./images/my/luffy-1.png";
import I2 from "./images/my/zoro-1.png";
import I3 from "./images/my/sanji.png";
import I4 from "./images/my/luffy-2.png";
import bg_10 from "./images/backgrounds/team-bg.png";
import bg_12 from "./images/background-shape/team-bg-dots.png";
import Button_GLogin from './Button';
import Heading from './Heading';
import naac from './images/my/nacc.png';
import c2 from './images/my/c2.png';

function SignIn({login}) {
    // const [ user, setUser ] = useState(null);
    // const [ profile, setProfile ] = useState(null);
    return(
        <>
<section className="fixed-top navigation">
  <div className="container">
    {/* <nav className="navbar navbar-expand-lg navbar-light">
      <a className="navbar-brand" href="index.html"><img src={"./images/logo.png"} alt="logo"/></a>
      <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
        aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse text-center" id="navbar">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="index.html">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#feature">Feature</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="about.html">About</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="service.html">Service</a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#team">Team</a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#pricing">Pricing</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="contact.html">Contact</a>
          </li>
        </ul>
        <a href="#" className="btn btn-primary ml-lg-3 primary-shadow">Try Free</a>
      </div>
    </nav> */}
  </div>
</section>

<section className="hero-section hero pt-5 for-luffy" data-background="" style={{backgroundImage: "url(" + Background + ")",marginBottom:100}}>
  <div className="container" >
    <div className="row">
      <div className="col-lg-12 text-center zindex-1">
        {/* <h1>Treasure Hunt</h1> */}
        <div style={{marginTop:-50,marginBottom:-90}} >
        <Heading/>
        </div>
        {/* <a href="#" className="btn btn-secondary btn-lg">explore us</a> */}
        <img className="img-fluid w-50 banner-image luffy" src={I1} />
      </div>
      {/* <div style={{float:'right',marginTop:350,marginLeft:750}} className='up-down-animation'>
        <Button_GLogin login={login} text={<>Huhhh… I’m coming !! <span style={{float:'right',color:'yellowgreen'}}>...Click</span></>}/>
      </div> */}
    </div>
  </div>
  <div id="scene">
    <img className="img-fluid hero-bg-1 up-down-animation" src={bg_0} alt=""/>
    <img className="img-fluid hero-bg-2 left-right-animation" src={bg_1} alt=""/>
    <img className="img-fluid hero-bg-3 left-right-animation" src={bg_2} alt=""/>
    <img className="img-fluid hero-bg-4 up-down-animation" src={bg_3} alt=""/>
    <img className="img-fluid hero-bg-5 left-right-animation" src={bg_4} alt=""/>
    <img className="img-fluid hero-bg-6 up-down-animation" src={bg_1} alt=""/>
    <img className="img-fluid hero-bg-7 left-right-animation" src={bg_6} alt=""/>
    <img className="img-fluid hero-bg-8 up-down-animation" src={bg_7} alt=""/>
    <img className="img-fluid hero-bg-9 up-down-animation" src={bg_8} alt=""/>
  </div>
</section>
<section className="section-lg seo" style={{marginBottom:-120}}>
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="seo-image">
          <img className="img-fluid" src={I2} style={{width:530}} alt="form-img"/>
        </div>
      </div>
      <div className="col-md-5">
        <h2 className="section-title" style={{marginTop:90}}>Event Will Be Hosted Throughout The Whole Campus</h2>
        <p>Experience One Piece Journey At Our Campus.</p>
      </div>
    </div>
  </div>
  <img className="img-fluid seo-bg" src={bg_5} alt="seo-bg"/>
  <img className="seo-bg-shape-1 left-right-animation" src={bg_1} alt="bg-shape"/>
  <img className="seo-bg-shape-2 up-down-animation" src={bg_2} alt="bg-shape"/>
  <img className="seo-bg-shape-3 left-right-animation" src={bg_9} alt="bg-shape"/>
</section>
<section className="section-lg service" style={{marginBottom:-150}}>
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-md-5 order-2 order-md-1">
        <h2 className="section-title" style={{marginTop:90}}>All One Piece Arcs Experience</h2>
        <p className="mb-4">Every Level, every checkpoint will be designed as the story of ONE PIECE flows through its arcs.</p>
        <ul className="pl-0 service-list">
          <li>All Arc Locations</li>
          <li>won't be easy to pass the level</li>
          <li>Surpricing Characters</li>
        </ul>
      </div>
      <div className="col-md-7 order-1 order-md-2">
        <img className="img-fluid layer-3" src={I3} alt="service"/>
      </div>
    </div>
  </div>
  <img className="img-fluid service-bg" src={bg_11} alt="service-bg"/>
  <img className="service-bg-shape-1 up-down-animation" src={bg_7} alt="background-shape"/>
  <img className="service-bg-shape-2 left-right-animation" src={bg_0} alt="background-shape"/>
</section>

<section className="section-lg team" id="team">
<div className="container">
    <div className="row justify-content-between">
      <div className="col-md-6">
        <img className="img-fluid layer-3" src={I4} alt="service"/>
      </div>
      <div className="col-md-6">
        <h2 className="section-title" style={{marginTop:90}}>Remember...</h2>
        <ul className="pl-0 service-list">
          <li>upto 6 members can participate as a team</li>
          <li>Registration will be closed on 17 feb</li>
          <li>Winners will get Exciting Anime Goodies</li>
        </ul>
      </div>
    </div>
  </div>
  <img src={bg_10} alt="team-bg" className="img-fluid team-bg"/>
  <img className="team-bg-shape-1 up-down-animation" src={bg_1} alt="background-shape"/>
  <img className="team-bg-shape-2 left-right-animation" src={bg_1} alt="background-shape"/>
  <img className="team-bg-shape-3 left-right-animation" src={bg_8} alt="background-shape"/>
  <img className="team-bg-shape-4 up-down-animation img-fluid" src={bg_12} alt="background-shape"/>
</section>

<section className="newsletter" >
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <h2>Register Your Pirate Crew Quickly</h2>
        <button onClick={login} className="btn btn-primary ml-lg-3 primary-shadow">Sign In / Register Now</button>
      </div>
    </div>
  </div>
  <img className="newsletter-bg-shape left-right-animation" src={bg_9} alt="background-shape"/>
</section>

<footer className="footer-section footer" style={{backgroundImage: "url(" + Footer + ")"}}>
  <div className="container">
    <div className="row pt-3">
    <div className="col-lg-12 text-center zindex-1" style={{marginTop:-200}}>
    <img src={c2} style={{width:80, float:'left'}}/>
    <img src={naac} style={{width:80, float:'right'}}/>
    <h4 className='mt-3 pt-2' style={{fontWeight:700,fontSize:25}}>SURYODAYA  COLLEGE  OF  ENGINEERING  AND  TECHNOLOGY,  NAGPUR</h4>
    <h3 className='neon'>YOUTH TECH CONNECT 2024</h3>
    <h5 className='mb-5' style={{fontSize:20,color:'#b393d3'}}>20 FEB | 8 AM ONWARDS</h5>
    <p className=''>Support Contact : @Tanmay 820822895 | @Kunal 7083005425 | @Aadesh 8830857304</p>
    <p>credits to @OnePiece @EiichiroOda @ToeiAnimation & Team</p>
    </div>
    </div>
  </div>
</footer>
</>
    );
}
export default SignIn;