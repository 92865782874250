// import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect, useRef } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import SignIn from './pages/SignIn'
import Registration from './pages/Registration';
import VerificationStatus from './pages/VerificationStatus';

function App() {
    const [ user, setUser ] = useState(null);
    const [ profile, setProfile ] = useState(null);
    const [ already, setAlready ] = useState(null);
    const topref = useRef(null);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse);
            // localStorage.setItem('codeResponse', codeResponse);
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            const local = localStorage.getItem('codeResponse')
            if (user || local) {
                const googleUser = user || local;
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${googleUser.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                        // localStorage.setItem('profile',res.data);
                        axios
                        .get(`${process.env.REACT_APP_API_URL}crew`)
                        .then((response) => {
                            const records = response.data;
                            setAlready(false);
                            records.map(e => {
                                if(res.data.email === e.email) {
                                    setAlready(e.id);
                                }
                            })
                        })
                        .catch((error) => {
                        console.error('Error submitting form:', error);
                        });
                        // localStorage.setItem("user",JSON.stringify(res.data));
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user, localStorage.getItem('profile') ]
    );

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
        // localStorage.setItem('profile',null);
        setAlready(null);
    };

    const setData = (data) => {
        setAlready(data);
    };

    const scrollTop = () => {
        topref.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
    };



    return (
        <div ref={topref}>
            {profile 
            // || localStorage.getItem('profile')
             ? (
                <>
                {already ? 
                    <>
                    <VerificationStatus id={already} logout={logOut}/> 
                    </>
                :
                    <>
                    <Registration 
                    login={profile 
                        // || localStorage.getItem('profile')
                    } 
                    logout={logOut} added={setData}/>
                    </>}
                </>
            ) : (<>
                    <SignIn login={() => {
                        login();
                        scrollTop();
                    }}/>
                </>
            )}
        </div>
    );
}
export default App;