import React from 'react';
import "./Button.css"
import logo1 from "./images/my/one-piece.png"
import logo2 from "./images/my/treasure-hunt.png"
import cross from "./images/my/cross.png"
import naac from './images/my/nacc.png';
import c1 from './images/my/c1.png';
import c2 from './images/my/c2.png';


function Heading({login}) 
{
return (<>
{/* <div class="box box1" onClick={login}>
  <div class="oddboxinner"> Take Me !!! <span style={{float:'right',color:'yellowgreen'}}>...Click</span></div>
</div> */}
<img src={c2} style={{width:80, float:'left'}}/>
<img src={naac} style={{width:80, float:'right'}}/>
<h4 className='mt-3 pt-3' style={{fontWeight:700,fontSize:25}}>SURYODAYA  COLLEGE  OF  ENGINEERING  AND  TECHNOLOGY,  NAGPUR</h4>
<h3 className='neon'>YOUTH TECH CONNECT 2024</h3>
<h5 className='' style={{fontSize:20,color:'#b393d3'}}>20 FEB | 8 AM ONWARDS</h5>
<div class="box_h box2" style={{marginBottom:-50}}>
<div class="oddboxinner2">We are Invinting You to the Pirate fest for  Hunting one of the Greatest Treasure</div>
</div>
<img src={logo1} className='logo' />
<img src={cross} className='logo-x'/>
<img src={logo2} className='logo2'/>
{/* <div class="evenboxinner">YOUTH TECH CONNECT</div> */}

{/* <div class="box box3">
  <div class="oddboxinner">yyyy</div>
</div> */}
</>
) 
}
export default Heading