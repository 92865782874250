import React from 'react';
import "./Button.css"

function Button_GLogin({login, text}) 
{
return (<>
<div class="box_c box1">
  <div class="oddboxinner">{text}</div>
</div>
{/* <div class="box box2">
  <div class="evenboxinner"> bangers!</div>
</div> */}
{/* <div class="box box3">
  <div class="oddboxinner">yyyy</div>
</div> */}
</>
)
}
export default Button_GLogin